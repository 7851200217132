<template>
  <div>
    <b-row align-v="center" id="login-area">
      <b-col></b-col>
      <b-col cols="6">
        <div>
          <img src="../assets/BPP_branco.png" id="logo" />
        </div>
        <b-card class="text-start">
          <b-form @submit="onSubmit" v-if="show">
            <span>{{ loginText }}</span>
            <b-input-group
              id="input-group-1"
              label-for="login"
              class="inpt-grp"
            >
              <b-input-group-prepend is-text>
                <b-icon icon="person-fill"></b-icon>
              </b-input-group-prepend>
              <b-form-input
                id="login"
                v-model="form.username"
                type="text"
                placeholder="Digite seu usuário"
                required
              ></b-form-input>
            </b-input-group>

            <span>{{ passwordText }}</span>
            <b-input-group
              id="input-group-2"
              label-for="password"
              class="inpt-grp"
            >
              <b-input-group-prepend is-text>
                <b-icon icon="person-fill"></b-icon>
              </b-input-group-prepend>
              <b-form-input
                id="password"
                v-model="form.password"
                type="password"
                placeholder="Digite sua senha"
                required
              ></b-form-input>
            </b-input-group>

            <b-button type="submit" variant="primary" id="submit"
              >Entrar</b-button
            >
          </b-form>
        </b-card>
      </b-col>
      <b-col></b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "LoginForm",
  components: {},
  props: {
    loginText: String,
    passwordText: String,
    submitText: String,
    checkedText: String,
  },
  data() {
    return {
      form: {
        username: "",
        password: null,
      },
      show: true,
    };
  },
  methods: {
    onSubmit(event) {
      this.$root.loading = true;
      event.preventDefault();
      const data = {
        user: this.form.username,
        password: this.form.password,
        microservice: "operationallimit"
      };

      this.axios
        .post("/auth/token", data)
        .then(() => {
          if (document.location.pathname === "/") {
            this.$router.push("/home");
            this.$root.$emit("notify", {
              label: "Login efetuado com sucesso",
              time: 5,
              type: "success",
            });
          } else {
            window.location.reload();
          }
          this.$root.loading = false;
        }).catch(error => {
          let errorMessage = null
          if (error.response && error.response.status === 401) {
            errorMessage = error.response.data.errors[0].message            
          }
          this.$root.$emit('notify', { label: errorMessage, time: 5, type: 'danger'})
        });
    },
  },
  mounted() {
    this.$root.pessoaId = undefined;
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("pessoaId");
  },
};
</script>

<style scoped>
#login-area {
  height: 100vh;
}
#logo {
  width: 85px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 23px;
}
#submit {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 120px;
}
.inpt-grp {
  margin-bottom: 15px;
}

span {
  font-weight: bold;
}
</style>
