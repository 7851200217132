<template>
  <div>
    <b-container>
      <LoginForm login-text="Usuário:" password-text="Senha:" />
    </b-container>
  </div>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";

export default {
  name: "Login",
  components: {
    LoginForm,
  }
};
</script>